<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
    id="adyen-encrypted-form"
  >
    <label for="creditCardNumber">Número do cartão de crédito para cobrança</label>
    <div class="input__flag">
      <v-text-field
        data-encrypted-name="number"
        v-model="form.creditCardNumber"
        type="tel"
        placeholder="•••• •••• •••• ••••"
        id="creditCardNumber"
        autocomplete="off"
        v-mask="creditCardConfig.mask"
        :minlength="creditCardConfig.minlength"
        :maxlength="creditCardConfig.maxlength"
        outlined
        required
        v-validate="{ required: true, creditCardFlags: true }"
        :data-vv-name="'número do cartão'"
        :error-messages="errors.collect('número do cartão')"
      />

      <transition name="fade">
        <div v-if="creditCardFlagsAllowed" class="input__flag__img">
          <img
            :src="require(`svg/credit-card-flags/${creditCardConfig.flag}.svg`)"
            :alt="creditCardConfig.flag"
          >
        </div>
      </transition>
    </div>

    <label for="creditCardHolderName">Nome</label>
    <v-text-field
     data-encrypted-name="holderName"
      :value="form.creditCardHolderName"
      placeholder="Nome impresso no cartão de crédito"
      id="creditCardHolderName"
      autocomplete="off"
      outlined
      required
      v-validate="{ required: true }"
      :data-vv-name="'nome'"
      :error-messages="errors.collect('nome')"
      @input="handleCreditCardHolderName"
    />

    <v-layout>
      <v-flex xs6>
        <div class="pr-2">
          <label for="creditCardExpiryDate">Validade</label>
          <v-text-field
            v-model="form.creditCardExpiryDate"
            type="tel"
            placeholder="•• / ••"
            id="creditCardExpiryDate"
            autocomplete="off"
            v-mask="'## / ##'"
            outlined
            required
            v-validate="{ required: true, creditCardExpirationDate: true }"
            :data-vv-name="'validade'"
            :error-messages="errors.collect('validade')"
          />
        </div>
          <input type="hidden" data-encrypted-name="expiryMonth" :value="getExpiryMonth" />
          <input type="hidden" data-encrypted-name="expiryYear" :value="getExpiryYear" />
      </v-flex>

      <v-flex xs6>
        <div class="pl-2">
          <label for="creditCardCVV">CVV</label>
          <div class="input__flag">
            <v-text-field
              data-encrypted-name="cvc"
              v-model="form.creditCardCVV"
              type="tel"
              placeholder="•••"
              id="creditCardCVV"
              autocomplete="off"
              maxlength="4"
              outlined
              required
              v-validate="{ required: true, min: 3, max: 4 }"
              :data-vv-name="'CVV'"
              :error-messages="errors.collect('CVV')"
            />
            <input type="hidden" data-encrypted-name="generationtime" :value="generationTime" />
            <div class="input__flag__img">
              <img src="@/assets/svg/icons/cvv.svg" alt="Cartão CVV">
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <Disclaimer class="my-1 pa-0 text--left" no-background>
      Para validar o seu crédito, iremos efetuar agora uma transação de R$ 250,00 no seu cartão de crédito. Mas não se preocupe, ela será estornada em alguns instantes.
    </Disclaimer>

    <Disclaimer class="text--left">
      Clicando em “concluir o cadastro” você declara ter autorizado a cobrança diária por cartão de crédito.
    </Disclaimer>

    <FormFooter right>
      <v-btn
        type="submit"
        color="warning--gradient"
        :loading="isLoading"
        :disabled="isLoading"
        depressed
        rounded
        large
      >
        Concluir cadastro
      </v-btn>
    </FormFooter>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import { isObject } from 'utils/functions'
import { STATUS_IS_LOADING, STATUS_SUCCESS, STATUS_FAILED } from 'utils/constants'
import formValidatorMixin from 'mixins/formValidator'
import persistProspect from 'mixins/persistProspect'
import creditCard from 'utils/creditCard'
import Disclaimer from 'components/Disclaimer'
import FormFooter from 'components/FormFooter'

export default {
  name: 'Form',
  mixins: [formValidatorMixin, persistProspect],
  directives: { mask },
  components: {
    Disclaimer,
    FormFooter
  },
  computed: {
    ...mapState({
      creditCardAttempts: ({ prospect }) => prospect.creditCardAttempts,
      prospect: ({ prospect }) => prospect.data
    }),

    creditCardConfig () {
      return creditCard.getConfig(this.form.creditCardNumber.replace(/\D/g, ''))
    },

    creditCardFlagsAllowed () {
      const flagsAllowed = ['visa', 'mastercard', 'elo', 'hipercard', 'diners', 'amex']
      return flagsAllowed.includes(this.creditCardConfig.flag)
    },

    isLoading () {
      return this.currentStatus === STATUS_IS_LOADING
    },

    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },

    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },

    generationTime () {
      return new Date().toISOString()
    },

    getExpiryMonth () {
      return this.form.creditCardExpiryDate ? this.form.creditCardExpiryDate.replace(/\D/g, '').slice(0, 2) : ''
    },

    getExpiryYear () {
      return this.form.creditCardExpiryDate ? `20${this.form.creditCardExpiryDate.replace(/\D/g, '').slice(2)}` : ''
    },

    getNumber () {
      return this.form.creditCardNumber ? this.creditCard.creditCardNumber.replace(/\D/g, '') : ''
    }
  },
  data () {
    return {
      currentStatus: null,
      valid: true,
      form: {
        creditCardNumber: '',
        creditCardHolderName: '',
        creditCardExpiryDate: '',
        creditCardCVV: ''
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'snackbar/show'
    }),

    handleCreditCardHolderName (value) {
      this.form.creditCardHolderName = value.toUpperCase()
    },

    redirectToNextStep () {
      this.$router.push({ name: 'RegistrationSubmitted' })
    },

    invalidCreditCard () {
      const creditCardAttempts = this.creditCardAttempts + 1
      const page = creditCardAttempts <= 3 ? { name: 'CreditCardDeniedAttempts' } : { name: 'CreditCardDenied' }
      this.saveProspect({ creditCardAttempts })
      this.$router.push(page)
    },

    isValidObject (val) {
      return isObject(val)
    },

    isInvalidRequestResponse (res) {
      return !this.isValidObject(res) || (this.isValidObject(res) && !res.valid)
    },

    async handleSubmit (creditCardEncrypted) {
      const isValid = await this.$validator.validateAll()

      try {
        if (isValid) {
          this.currentStatus = STATUS_IS_LOADING

          if (!creditCardEncrypted) {
            this.invalidCreditCard()
            return
          }

          const { id } = this.prospect
          const { data: res } = await this.$apiService.step6.creditCardPayment({
            id,
            creditCardEncrypted
          })

          if (this.isInvalidRequestResponse(res)) {
            this.invalidCreditCard()
            throw new Error()
          }

          this.saveProspect({
            currentStep: 4,
            data: {
              creditCardEncrypted,
              paymentMethod: 'creditCard'
            }
          })

          this.currentStatus = STATUS_SUCCESS
          this.redirectToNextStep()
        }
      } catch (error) {
        this.currentStatus = STATUS_FAILED

        if (error && error.response && (status === 404 || error.response.status === 500)) {
          this.showAlert({
            type: 'error',
            message: 'Ocorreu um erro ao enviar as informações, tente novamente mais tarde.',
            timeout: 8000
          })
        }

        return error
      }
    }
  },
  created () {
    this.$validator.extend('creditCardFlags', {
      getMessage: field => 'Número inválido ou bandeira do cartão não permitida',
      validate: value => this.creditCardFlagsAllowed
    })
  },
  mounted () {
    const form = document.getElementById('adyen-encrypted-form')
    if (!form) return

    const self = this
    var encryptedBlobFieldName = 'adyen-encrypted-data'

    const options = {
      name: encryptedBlobFieldName,
      onsubmit: function (e) {
        var encryptedData = form.elements[encryptedBlobFieldName].value
        self.handleSubmit(encryptedData)

        e.preventDefault()
      }
    }
    window.adyen.createEncryptedForm(form, options)
  }
}
</script>

<style lang="scss" scoped>
.input__flag {
  position: relative;
}

.input__flag__img {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 42px;

  img {
    max-width: 28px;
  }
}
</style>
